import React from "react"
import Seo from "../../../components/seo"
import Layout from "../../../components/layout"
import Title from "../../../components/title"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Section from "../../../components/section"
import Button from "../../../components/button"
import Technologies from "../../../components/technologies"
import * as styles from "./fe.module.scss"

export default function FrontendDevelopmentPage({ data }) {
  return (
    <>
      <Seo title="Blazing fast JAMstack websites can help you rank better among other things." />
      <Layout>
        <section className={styles.jamstack}>
          <header>
            <Title headingLevel="h4">
              Why work with a company specialised in
            </Title>
            <Title headingLevel="h1">Frontend Development</Title>
            <Title headingLevel="p">
              While UI and UX design focus mostly on usability and the visual
              experience of digital products, front-end development is truly
              what makes user interfaces come to life. A smart front end
              development business realises this and develops products that turn
              users into paying clients.
            </Title>
            <Title headingLevel="p">
              The front-end element of web software, sometimes known as
              "client-side," uses code that runs inside the user's browser to
              power all interactive capabilities of the programme.
            </Title>
            <Title headingLevel="p">
              A front-end development business should include testing or
              debugging chores in addition to developing web design elements and
              ensuring optimal connection with the back-end code.
            </Title>
            <Button to="/start-project">Start project</Button>
          </header>
          <div className={styles.jamstackHero}>
            <GatsbyImage alt="" image={data.frontend.sharp.gatsbyImageData} />
          </div>
        </section>

        <Technologies />

        <Section className={styles.otherServices} highlight>
          <Title headingLevel="h4">Looking for other services?</Title>
          <Title headingLevel="h2">UI/UX Development</Title>
          <Title headingLevel="p">
            Integrating UX/UI designs and wireframes into a user-friendly,
            mobile-first, accessible, and intuitive interface
          </Title>
          <Button to="/services/user-interface-development">Read more</Button>
        </Section>
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    frontend: file(relativePath: { eq: "frontend.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    words: file(relativePath: { eq: "words.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    gatsbyjs: file(relativePath: { eq: "gatsbyjs.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    performance: file(relativePath: { eq: "performance.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    seo: file(relativePath: { eq: "seo.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    safety: file(relativePath: { eq: "safety.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    content: file(relativePath: { eq: "content.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    chart: file(relativePath: { eq: "chart.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`
